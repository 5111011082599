/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "caption_colors_default": "",
  "caption_colors_alternative": "",
  "caption_size_default": "prose",
  "caption_size_alternative": "prose",
  "caption_variant_default": "sm:col-start-2",
  "caption_variant_alternative": "sm:col-start-2 order-1",
  "email_colors_default": "text-primary-700",
  "email_colors_alternative": "",
  "email_size_default": "text-headline-sm",
  "email_size_alternative": "text-headline-sm -mt-4",
  "email_variant_default": "sm:col-start-2",
  "email_variant_alternative": "order-2",
  "description_colors_default": "",
  "description_colors_alternative": "",
  "description_size_default": "text-body-md my-3",
  "description_size_alternative": "prose",
  "description_variant_default": "sm:col-start-2",
  "description_variant_alternative": "sm:col-start-2",
  "headline_colors_default": "",
  "headline_colors_alternative": "border-primary-900",
  "headline_size_default": "text-headline-md mb-2",
  "headline_size_alternative": "text-headline-lg sm:pb-6 sm:border-b",
  "headline_variant_default": "sm:col-start-2",
  "headline_variant_alternative": "col-span-full sm:order-first",
  "image_group_colors_default": "",
  "image_group_colors_alternative": "",
  "image_group_size_default": "sm:max-w-45",
  "image_group_size_alternative": "sm:max-w-95",
  "image_group_variant_default": "sm:row-span-5",
  "image_group_variant_alternative": "sm:row-span-5",
  "image_colors_default": "",
  "image_colors_alternative": "",
  "image_size_default": "w-1/2 sm:w-45 data-[fill]:aspect-square",
  "image_size_alternative": "w-full sm:w-45 md:w-95 data-[fill]:aspect-[4/3]",
  "image_variant_default": "rounded-full",
  "image_variant_alternative": "rounded-none",
  "soMe_group_colors_default": "",
  "soMe_group_colors_alternative": "",
  "soMe_group_size_default": "gap-x-3 my-1.5",
  "soMe_group_size_alternative": "gap-x-3",
  "soMe_group_variant_default": "flex flex-row sm:col-start-2",
  "soMe_group_variant_alternative": "flex flex-row sm:col-start-2 order-3",
  "soMe_colors_default": "",
  "soMe_colors_alternative": "",
  "soMe_size_default": "",
  "soMe_size_alternative": "",
  "soMe_variant_default": "",
  "soMe_variant_alternative": "",
  "colors_default": "bg-primary-100",
  "colors_alternative": "bg-white",
  "size_default": "w-full max-w-article-lg p-3 gap-x-6",
  "size_alternative": "w-full p-6 sm:p-0 gap-y-3 sm:gap-x-6 sm:gap-y-6",
  "variant_default": "grid grid-cols-1 sm:grid-cols-[min-content_auto] rounded-lg",
  "variant_alternative": "grid grid-cols-1 sm:grid-cols-[min-content_auto]"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "AuthorDetails");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;